import { useRecoilState } from 'recoil';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Close from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import Typography from '@mui/material/Typography';

import { uploadFilesModal } from 'common/data/modalState';
import { uploadFilesSchema } from 'services';
import { useUploadDocsHooks } from 'services';
import formState from 'common/data/formState';
import t from 'common/utils/translations';

import Loading from 'common/components/Loading';
import PdfModalIcon from 'svg/Flow/PdfModalIcon';
import UploadIcon from 'svg/Flow/UploadIcon';

const UploadFilesModal = ({
  errorUpload,
  executeRequirements,
  executeUpload,
}) => {
  const [isOpen, setOpen] = useRecoilState(uploadFilesModal);
  const [values, setValues] = useRecoilState(formState);
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [fileName, setFileName] = useState(null);

  const { executeUploadDocs } = useUploadDocsHooks();

  const {
    accessToken,
    activeSubStep,
    permitApplicationDocumentId,
    permitApplicationLogId,
  } = values;

  const handleClose = () => setOpen(false);

  const submitFile = async file => {
    try {
      setError(null);
      setLoading(true);

      const { length } = file;
      if (length === 0) {
        return false;
      }

      const fileTypes = [
        'application/pdf',
        'image/jpg',
        'image/jpeg',
        'image/png',
        '.csv',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
      ];
      const { size, type, name } = file;

      if (!fileTypes.includes(type)) {
        setError('File format must be pdf, xls, jpg, jpeg or png.');
        setLoading(false);
        return false;
      }
      if (size / 1024 / 1024 > 25) {
        setError('File size exceeded the limit of 25MB.');
        setLoading(false);
        return false;
      }
      setFileName(name);
      setError(null);

      const docs = await executeUploadDocs({
        data: {
          file,
          permitApplicationLogId,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (docs?.data) {
        const response = await executeUpload({
          data: uploadFilesSchema({
            permitApplicationDocumentId,
            permitApplicationLogId,
            url: docs.data?.url,
          }),
          headers: { Authorization: `Bearer ${accessToken}` },
        });

        if (!permitApplicationLogId) {
          setValues(state => ({
            ...state,
            permitApplicationLogId: response?.data?.permitApplicationLogId,
            permitApplicationStatus: response?.data?.status,
          }));
        }

        if (response?.status === 200 && permitApplicationLogId) {
          await executeRequirements({
            params: {
              permitApplicationStepId: activeSubStep,
              permitApplicationLogId: response?.data?.permitApplicationLogId,
            },
            headers: { Authorization: `Bearer ${accessToken}` },
          });

          handleClose();
        } else {
          handleClose();
        }
      }
    } catch (e) {
      setError('Something went wrong. Please refresh your page and try again.');
      setLoading(false);
    }
  };

  const onDrop = async e => {
    e.preventDefault();
    const {
      dataTransfer: { files },
    } = e;
    setError(null);

    const { length } = files;
    if (length === 0) {
      return false;
    }

    submitFile(files[0]);
  };

  const onDragOver = e => {
    e.preventDefault();
  };

  const onFileUpload = async e => {
    const fileBrowse = document.getElementById('pdf').files[0];
    setError(null);

    if (!fileBrowse) {
      return false;
    }

    submitFile(fileBrowse);
  };

  return (
    <Dialog disableEscapeKeyDown fullWidth maxWidth="md" open={isOpen}>
      <DialogTitle>
        <Box display="flex" justifyContent="flex-end">
          <IconButton aria-label="Close" onClick={handleClose}>
            <Close color="primary" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent data-cy="upload-files">
        <Box display="flex" justifyContent="center" pb={2} width="100%">
          <Typography sx={{ color: '#ff0000' }} variant="body2">
            Note: Only PDF, XLS, JPG, JPEG, and PNG files are allowed.
          </Typography>
        </Box>
        <Box
          alignItems="center"
          bgcolor="#f6f9ff"
          border="1px dashed #096bef"
          borderRadius="6px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          mb={5}
          mx={5}
          onDragOver={onDragOver}
          onDrop={onDrop}
          p={10}
        >
          {isLoading && (
            <Fragment>
              <Box mb={1}>
                <PdfModalIcon />
              </Box>
              <Box mb={1}>
                <Typography variant="body2">Uploading....</Typography>
              </Box>
              <Box>
                <Typography variant="body2">{fileName}</Typography>
              </Box>
              <Box>
                <Loading />
              </Box>
            </Fragment>
          )}

          {!isLoading && (
            <Fragment>
              <Box mb={2}>
                <UploadIcon />
              </Box>
              <Box mb={2}>
                <Typography variant="body2">
                  Drag & Drop Your Files Here
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant="body2">OR</Typography>
              </Box>
              <Button
                component="label"
                sx={{
                  background:
                    'linear-gradient(90deg, #77D2FF 0%, #489DFF 91.89%)',
                  mb: 3,
                  px: 5,
                  py: 2,
                }}
                variant="contained"
              >
                BROWSE FILE
                <input
                  accept={t.acceptApplications}
                  hidden
                  id="pdf"
                  onChange={onFileUpload}
                  type="file"
                />
              </Button>
              <Box color="#9e9e9e" mb={3}>
                <Typography variant="caption">
                  Maximum of file to be uploaded is 25MB
                </Typography>
              </Box>
              {error && (
                <Box color="#ff0000">
                  <Typography variant="caption">{error}</Typography>
                </Box>
              )}
              {errorUpload && (
                <Box color="#ff0000">
                  <Typography variant="caption">
                    Something went wrong while trying to upload your document.
                  </Typography>
                </Box>
              )}
            </Fragment>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

UploadFilesModal.propTypes = {
  errorUpload: PropTypes.string,
  executeRequirements: PropTypes.func.isRequired,
  executeUpload: PropTypes.func.isRequired,
};

export default UploadFilesModal;
